import React, { useEffect, useState } from "react"
import Number from "./Number"

const random = require("crypto-random-int")

const shuffle = async a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = await random(0, i + 1);
    [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const getUniqueNumbers = (max, count) => shuffle([...Array(max).keys()]).then(numbers => numbers.filter(i=>!isNaN(i)).map(i => i + 1).slice(0, count))

const getNumbersWithRepetitions = (count, max) => Promise.all(Array(count).fill(0).map(() => random(1, max)))

const getNumbers = (max, count, unique, sorted) => {
  const numbers = unique ? getUniqueNumbers(max, count) : getNumbersWithRepetitions(count, max)
  return sorted ? numbers.then(a => a.sort()) : numbers
}

const display = (numbers, extra) => numbers.map(
  (number, i) => <Number number={number} key={i} extra={extra} />,
)

const NumberSet = ({ count, max, extraCount, extraMax, unique, sorted, rnd }) => {
  const [numbers, setNumbers] = useState([])
  const [extraNumbers, setExtraNumbers] = useState([])
  useEffect(
    () => {
      getNumbers(max, count, unique, sorted).then(result => setNumbers(result))
      getNumbers(extraMax, extraCount, unique, sorted).then(result => setExtraNumbers(result))
    },
    [count, max, extraCount, extraMax, unique, sorted, rnd]
  )
  return (<div>
    {display(numbers, false)}
    {display(extraNumbers, true)}
  </div>)
}
export default NumberSet
