import React from "react"
import NumberGenerator from "../components/tools/NumberGenerator"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import FAQItem from "../components/FAQItem"

const LotteryNumberGenerator = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="Lottery Number Generator"
         description="Are you looking for a way to quickly and easily generate a set of truly random numbers? Do you need a handy tool to simulate a lottery drawing?"
    />
    <h1>Tool for Simulating Lottery Draws - Random Lottery Number Generator</h1>
    <p>Are you looking for a way to quickly and easily generate a set of truly random numbers? Do you need a handy tool
      to simulate a lottery drawing?</p>
    <p><strong>
      This random lottery number generator tool can generate multiple sets of random numbers for <a
      href="https://powerball.com" target="_blank" rel="noreferrer">Powerball</a>, <a
      href="https://www.megamillions.com" target="_blank" rel="noreferrer">Mega Millions</a>, Pick3, Pick4, <a
      href="https://www.euro-millions.com/" target="_blank" rel="noreferrer">EuroMillions</a>, or any other popular
      lottery game.
    </strong></p>
    <NumberGenerator />
    <h2>FAQ</h2>
    <section itemScope itemType={"https://schema.org/FAQPage"}>
      <FAQItem question="What Games Can the Lottery Number Generator Simulate?">
        <strong>Our lottery number generator is highly configurable, it can be used to simulate any popular lottery
          game, including, but not limited to <a href="https://powerball.com" target="_blank"
                                                 rel="noreferrer">Powerball</a>, <a
            href="https://www.megamillions.com"
            target="_blank" rel="noreferrer">Mega
            Millions</a>, Pick3, Pick4, Hot Lotto, <a href="https://www.euro-millions.com/" target="_blank"
                                                      rel="noreferrer">
            Euromillions</a>, <a href="https://www.luckyforlife.us/" target="_blank" rel="noreferrer"> Lucky for
            Life</a> or <a
            href="https://www.national-lottery.co.uk/games/thunderball" target="_blank"
            rel="noreferrer">Thunderball</a>.</strong><br />
        It is not limited to these games though, you can set it up to simulate any existing or imaginary lottery game.
      </FAQItem>
      <FAQItem question="How Much Does It Cost to Use the Lottery Number Generator? Is It a Free Tool?">
        <strong><Link to="/">LottoLibrary.com</Link>'s lottery number generator tool is absolutely free to
          use.</strong> There are no hidden fees or charges.<br />
        <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
          our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
      </FAQItem>
      <FAQItem question="How Many Numbers Can I Generate? Is There A Limit?">
        <strong><Link to="/">LottoLibrary.com</Link> does not limit the usage of the lottery number generator
          tool.</strong> You can generate as many sets of numbers as you wish - all free of charge.
      </FAQItem>
      <FAQItem question="How Safe Is the Lottery Number Generator?">
        We use a technology called <a href="https://www.ecma-international.org/publications/standards/Ecma-262.htm"
                                      target="_blank" rel="noreferrer">client-side scripting</a> that enables us to
        generate the results on your local device, instead of on the LottoLibrary server. That means that <strong>your
        numbers are never exposed on the internet - no one else can see them or modify them.</strong><br />
        The program code of the lottery number generator is transferred to your device on a secure channel,
        protected by <a href="https://en.wikipedia.org/wiki/Transport_Layer_Security" target="_blank"
                        rel="noreferrer">TLS</a>, which guarantees that it cannot be read or modified by malicious
        actors. Our TLS certificate has been issued by <a href="https://letsencrypt.org/" target="_blank"
                                                          rel="noreferrer">Let's Encrypt</a>
      </FAQItem>
      <FAQItem question="How Does the Lottery Number Generator Work?">
        To simulate a random, unbiased lottery draw, <strong>this tool uses the <a
        href="https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle" target="_blank" rel="noreferrer">Fisher-Yates
        shuffle
        algorithm</a> (also known as Knuth shuffle) to generate a random permutation of all the numbers of the
        selected
        range</strong>, then picks the first elements from the shuffled array of numbers.
        <br />The exact number of elements to be selected is determined by the configuration parameters set by the
        user.
      </FAQItem>
      <FAQItem question="How Random is the Lottery Number Generator?">
        <strong>Lottolibrary's lottery number generator is guaranteed to generate random results.</strong> Our tool
        uses
        the <a href="https://www.npmjs.com/package/crypto-random-int" target="_blank"
               rel="noreferrer">Crypto-Random-Int</a> npm package, which relies on your browser's builtin random
        number
        generator. This package uses the <a
        href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues" target="_blank"
        rel="noreferrer">Web
        Cryptography API</a>, which is guaranteed to produce cryptographically strong random numbers, by properly
        seeding a PRNG with the adequate amount of entropy. <br />
        In contrast, most implementations on the web use a much weaker method - an improperly <a
        href="https://www.statisticshowto.com/random-seed-definition/" target="_blank" rel="noreferrer">seeded</a> <a
        href="https://en.wikipedia.org/wiki/Pseudorandom_number_generator" target="_blank" rel="noreferrer">pseudorandom
        number generator</a> (e.g.: <code>Math.random()</code>)
      </FAQItem>
    </section>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-odds-calculator/">Lottery Odds Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-tax-calculator/">Lottery Tax Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-annuity-calculator/">Lottery Annuity Payout Calculator</Link>
        </li>
        <li>
          <Link to="/best-odds/">Lottery Odds Comparison</Link>
        </li>
        <li>
          <Link to="/expected-value-calculator/">Expected Value Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Related Articles</h2>
    <section>
      <ul>
        <li>
          <Link to="/can-you-predict-the-lottery/">Can You Predict The Lottery?</Link>
        </li>
        <li>
          <Link to="/are-lotteries-rigged/">Are Lotteries Rigged?</Link>
        </li>
      </ul>
    </section>
    <h2>Sources</h2>
    <section>
      <ul>
        <li>
          <a href="https://powerball.com"
             target="_blank"
             rel="noreferrer"
          >Powerball</a>
        </li>
        <li>
          <a href="https://www.megamillions.com"
             target="_blank"
             rel="noreferrer"
          >Mega Millions</a>
        </li>
        <li>
          <a href="https://www.national-lottery.co.uk/games/thunderball"
             target="_blank"
             rel="noreferrer"
          >Thunderball</a>
        </li>
        <li>
          <a href="https://www.euro-millions.com/"
             target="_blank"
             rel="noreferrer"
          >EuroMillions</a>
        </li>
        <li>
          <a href="https://www.luckyforlife.us/"
             target="_blank"
             rel="noreferrer"
          >Lucky for Life</a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle"
             target="_blank"
             rel="noreferrer"
          >Fisher-Yates shuffle</a>
        </li>
        <li>
          <a href="https://www.npmjs.com/package/crypto-random-int"
             target="_blank"
             rel="noreferrer"
          >Crypto-Random-Int</a>
        </li>
        <li>
          <a href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues"
             target="_blank"
             rel="noreferrer"
          >Web Cryptography API</a>
        </li>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Pseudorandom_number_generator"
            target="_blank"
            rel="noreferrer"
          >Pseudorandom number generator (PRNG)</a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Transport_Layer_Security"
             target="_blank"
             rel="noreferrer">Transport Layer Security (TLS)</a>
        </li>
        <li>
          <a href="https://letsencrypt.org/"
             target="_blank"
             rel="noreferrer">Let's Encrypt</a>
        </li>
        <li>
          <a href="https://www.ecma-international.org/publications/standards/Ecma-262.htm"
             target="_blank"
             rel="noreferrer">ECMAScript</a>
        </li>
        <li>
          <a href="https://www.statisticshowto.com/random-seed-definition/"
             target="_blank"
             rel="noreferrer">Seeding random number generators</a>
        </li>
      </ul>
    </section>


  </Layout>
export default LotteryNumberGenerator
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
