import React from "react"
import NumberSet from "./NumberSet"

const NumberSetList = ({ numberOfSets, count, max, extraCount, extraMax, unique, sorted, rnd }) => <>
  {[...Array(numberOfSets)].map(
    (_, i) => <NumberSet
      key={i}
      count={count}
      max={max}
      extraCount={extraCount}
      extraMax={extraMax}
      unique={unique}
      sorted={sorted}
      rnd={rnd}
    />,
  )}
</>
export default React.memo(NumberSetList)
