import React, { useRef, useState } from "react"
import NumberSetList from "./NumberSetList"
import GameOptions from "../GameOptions"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"


const NumberGenerator = ({ gameOptions }) => {
  const [numberOfSets, setNumberOfSets] = useState(1)
  const [sorted, setSorted] = useState(true)
  const [options, setOptions] = useState({ display: false })
  const [displayNumberSets, setDisplayNumberSets] = useState(false)
  const submitRef = useRef(null)

  const updateOptions = () => {
    setOptions({
      numberOfSets,
      rnd: Math.random(),
      sorted,
      ...gameOptions,
    })
  }

  const generateNumbers = () => {
    updateOptions()
    setDisplayNumberSets(true)
    window.scrollTo(0, submitRef.current.offsetTop)
  }

  return (
    <Paper elevation={1}>
      <Box p={4}>
        <Typography variant="h4" component="h2">Lottery Draw Simulator</Typography>
        <Box p={2}>
          <GameOptions />
        </Box>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                type="number"
                min={1}
                max={100}
                defaultValue={numberOfSets}
                onChange={(e) => setNumberOfSets(parseInt(e.target.value))}
                label="Number of draws"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                label="Sort results"
                control={<Checkbox
                  color="primary"
                  type="checkbox"
                  defaultChecked={sorted}
                  onChange={(e) => setSorted(e.target.checked)}
                />}
              />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={generateNumbers} variant="contained" color="primary" ref={submitRef}>Go!</Button>
            </Grid>
          </Grid>
        </Box>
        {displayNumberSets &&
        <>
          <Typography variant="h4" component="h3">Results of the Simulated draw(s):</Typography>
          <br/>
          <NumberSetList {...options} />
        </>
        }
      </Box>
    </Paper>
  )

}
const mapStateToProps = ({ gameOptions }) => ({ gameOptions })

NumberGenerator.propTypes = {
  gameOptions: PropTypes.shape(
    {
      count: PropTypes.number,
      max: PropTypes.number,
      extraCount: PropTypes.number,
      extraMax: PropTypes.number,
      unique: PropTypes.bool,
    },
  ),
}
export default connect(mapStateToProps)(NumberGenerator)
